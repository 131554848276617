var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OrganizationsMainStyled', _vm._l(_vm.organizationsMapped, function (organization) {
    return _c('Panel', {
      key: organization.id,
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(organization.name) + " "), organization.isAdmin ? _c('VPopover', {
            attrs: {
              "placement": 'bottom-end',
              "open": _vm.isActive,
              "popperOptions": _vm.popperOptions,
              "popoverClass": 'userGuidePopOver',
              "container": '#app'
            },
            on: {
              "update:open": function updateOpen($event) {
                _vm.isActive = $event;
              }
            },
            scopedSlots: _vm._u([{
              key: "popover",
              fn: function fn() {
                return [_c('UserGuideStyled', [_c('div', {
                  staticClass: "title"
                }, [_c('StarIcon'), _c('h5', [_vm._v(_vm._s(_vm.$t('userGuide.title')))])], 1), _c('div', {
                  staticClass: "content"
                }, [_vm._v(" " + _vm._s(_vm.$t('userGuide.content')) + " ")]), [_c('ActionsStyled', [_c('ButtonBorderedStyled', {
                  on: {
                    "click": _vm.close
                  }
                }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")]), _vm.pdfLink ? _c('AButtonSolid', {
                  attrs: {
                    "href": _vm.pdfLink,
                    "target": "_blank"
                  },
                  on: {
                    "click": _vm.close
                  }
                }, [_vm._v(" " + _vm._s(_vm.$t('actions.check_it_out')) + " ")]) : _vm._e()], 1)]], 2)];
              },
              proxy: true
            }], null, true)
          }, [_c('span', {
            staticClass: "popover-trigger",
            class: {
              active: _vm.isActive
            }
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('actions.learn_more')))]), _c('HelpCircleIcon', {
            attrs: {
              "size": "1.2x"
            }
          })], 1)]) : _vm._e()];
        },
        proxy: true
      }, {
        key: "main",
        fn: function fn() {
          return [organization.isAdmin ? _c('div', {
            staticClass: "settings"
          }, [_c('div', {
            staticClass: "title"
          }, [_vm._v(" " + _vm._s(_vm.$tc('setting', 2)) + " ")]), _c('div', {
            staticClass: "cell option"
          }, [_c('OnOffToggle', {
            staticClass: "on-off-toggle",
            attrs: {
              "value": organization.nonAdminUsersCanSeeOtherUsers
            },
            on: {
              "toggle": function toggle($event) {
                return _vm.toggleNonAdminUsersCanSeeOtherUsers(organization);
              }
            }
          }), _c('span', [_vm._v(_vm._s(_vm.$t('nonAdminUsersCanSeeOtherUsers')))])], 1), _c('div', {
            staticClass: "cell option"
          }, [_c('OnOffToggle', {
            staticClass: "on-off-toggle",
            attrs: {
              "value": organization.dashboardSharingEnabledForAllUsers,
              "disabled": !organization.nonAdminUsersCanSeeOtherUsers
            },
            on: {
              "toggle": function toggle($event) {
                return _vm.toggleUserDashboardSharing(organization);
              }
            }
          }), _c('span', [_vm._v(_vm._s(_vm.$t('dashboardSharingForAllUsers')))])], 1)]) : _vm._e(), _c('div', {
            staticClass: "members"
          }, [_c('div', {
            staticClass: "title"
          }, [_vm._v(" " + _vm._s(_vm.$tc('user', 2)) + " ")]), _c('div', {
            staticClass: "cell username"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('username', 1)))])]), _c('div', {
            staticClass: "cell email"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('email', 1)))])]), _c('div', {
            staticClass: "cell role"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('role', 1)))])]), _c('div', {
            staticClass: "cell spacer"
          }), _vm._l(organization.memberships, function (membership) {
            return [_c('div', {
              key: 'username_' + membership.id,
              staticClass: "cell username",
              attrs: {
                "id": "sensitive"
              }
            }, [_vm._v(" " + _vm._s(membership.user.username) + " ")]), _c('div', {
              key: 'email_' + membership.id,
              staticClass: "cell email",
              attrs: {
                "id": "sensitive"
              }
            }, [_c('nobr', [_vm._v(_vm._s(membership.user.email))])], 1), _c('div', {
              key: 'role_' + membership.id,
              staticClass: "cell role"
            }, [organization.isAdmin ? _c('BasicSelect', {
              attrs: {
                "target": _vm.$tc('role', 1),
                "options": _vm.roles,
                "selected": membership.selectedRole
              },
              on: {
                "change": function change($event) {
                  return _vm.setRole($event, membership);
                }
              }
            }) : [_vm._v(" " + _vm._s(_vm.$tc("membershipRoles.".concat(membership.role.toLowerCase()))) + " ")]], 2), _c('div', {
              key: 'spacer_' + membership.id,
              staticClass: "cell spacer"
            }, [membership.userId === _vm.userId ? _c('BadgeStyled', [_vm._v(" " + _vm._s(_vm.$tc('itIsYou')) + " ")]) : _vm._e()], 1)];
          })], 2), _c('div', {
            staticClass: "subsidiaries"
          }, [_c('div', {
            staticClass: "title"
          }, [_vm._v(" " + _vm._s(_vm.$tc('subsidiary', 2)) + " ")]), organization.subsidiaries.length ? [_c('div', {
            staticClass: "cell name"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('name', 1)))])]), _c('div', {
            staticClass: "cell members"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('user', 2)))])]), _c('div', {
            staticClass: "cell assets"
          }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('asset', 2)))])]), _c('div', {
            staticClass: "cell spacer"
          }), _vm._l(organization.subsidiaries, function (subsidiary) {
            return [_c('div', {
              key: 'name_' + subsidiary.id,
              staticClass: "cell name value"
            }, [_c('nobr', [_vm._v(_vm._s(subsidiary.name))])], 1), _c('div', {
              key: 'members_' + subsidiary.id,
              staticClass: "cell members"
            }, [_vm._v(" " + _vm._s(subsidiary.memberships.length) + " ")]), _c('div', {
              key: 'assets_' + subsidiary.id,
              staticClass: "cell assets"
            }, [_vm._v(" " + _vm._s(subsidiary.assets.length) + " ")]), _c('div', {
              key: 'spacer_' + subsidiary.id,
              staticClass: "cell actions"
            }, [organization.isAdmin ? [_c('ButtonRound', {
              attrs: {
                "disabled": false
              },
              on: {
                "click": function click($event) {
                  return _vm.editSubsidiary(subsidiary);
                }
              }
            }, [_c('Edit2Icon')], 1), _c('ButtonRound', {
              attrs: {
                "disabled": false
              },
              on: {
                "click": function click($event) {
                  return _vm.confirmDeleteSubsidiary(subsidiary, organization);
                }
              }
            }, [_c('XIcon')], 1)] : [_c('ButtonRound', {
              attrs: {
                "disabled": false
              },
              on: {
                "click": function click($event) {
                  return _vm.editSubsidiary(subsidiary);
                }
              }
            }, [_c('MoreHorizontalIcon')], 1)]], 2)];
          })] : _c('div', {
            staticClass: "no-subsidiaries"
          }, [_c('i18n', {
            attrs: {
              "path": "messages.noDataWithType"
            },
            scopedSlots: _vm._u([{
              key: "type",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$tc('subsidiary', 2)) + " ")];
              },
              proxy: true
            }], null, true)
          })], 1), organization.isAdmin ? _c('div', {
            staticClass: "create-subsidiary"
          }, [_c('transition', {
            attrs: {
              "name": "fade-in-left"
            }
          }, [!organization.isEditing ? [_c('ButtonSolid', {
            on: {
              "click": function click($event) {
                _vm.organizationInEditModeId = organization.id;
              }
            }
          }, [_c('PlusIcon'), _c('span', [_vm._v(" " + _vm._s(_vm.$tc('actions.create')) + " ")])], 1)] : [_c('div', {
            staticClass: "input"
          }, [_c('ButtonConfirm', {
            on: {
              "click": function click($event) {
                _vm.organizationInEditModeId = null;
              }
            }
          }, [_c('XIcon')], 1), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.subsidiaryName,
              expression: "subsidiaryName"
            }],
            domProps: {
              "value": _vm.subsidiaryName
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) { return; }
                _vm.subsidiaryName = $event.target.value;
              }
            }
          }), _c('ButtonConfirm', {
            attrs: {
              "disabled": _vm.canCreateSubsidiary
            },
            on: {
              "click": function click($event) {
                return _vm.createSubsidiary(organization);
              }
            }
          }, [_c('CheckIcon')], 1)], 1)]], 2)], 1) : _vm._e()], 2)];
        },
        proxy: true
      }], null, true)
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }